<template>
  <v-app light>
    <v-main>
      <div class="container">
        <v-card color="primary" class="header pa-4 my-2 d-flex flex-row" elevation="0">
          <div class="mr-3">
            <v-icon large color="white"> mdi-waves </v-icon>
          </div>
          <div class="d-flex flex-column">
            <h2>Maritime Use Conflict Analysis Tools</h2>
            <div>กรุณาเลือกรูปแบบเครื่องมือที่ต้องการวิเคราะห์</div>
          </div>
        </v-card>

        <v-card class="d-flex flex-row pa-4 pa-md-12" elevation="0">
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <h3 class="mb-4">เครื่องมือการวิเคราะห์</h3>
              <v-hover v-slot="{ hover }" open-delay="100">
                <v-card
                  to="/msp/muc/matrix"
                  class="pa-2 d-flex flex-column flex-sm-column flex-md-row"
                  :elevation="hover ? 8 : 2"
                >
                  <img class="muc-image" src="@/assets/home/muc.png" alt="" />
                  <div class="d-flex flex-column pa-6">
                    <h2 class="my-3">
                      วิเคราะห์ความขัดแย้งแบบ matrix และการหา MUC Score
                    </h2>
                    <div
                      style="width: 24px; height: 4px; background-color: #61a4bc"
                    ></div>
                    <div class="subcolor--text sub-title-card my-6">
                      เครื่องมือวิเคราะห์ความขัดแย้งการใช้ประโยชน์ของพื้นที่และชายฝั่งได้
                      โดยจะมีผลลัพธ์ออกในรูปแบบ Grid และ Matrix ได้
                    </div>
                  </div>
                </v-card>
              </v-hover>
            </v-col>

            <v-col cols="12" sm="12" md="12">
              <h3 class="mb-4">ผลสรุปความขัดแย้งเศรษฐกิจและสังคม (GeoStory)</h3>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                  v-for="(item, index) in geostoryList"
                  :key="index"
                >
                  <v-hover v-slot="{ hover }" open-delay="100">
                    <v-card
                      :href="item.detail_url"
                      target="_blank"
                      class="pa-2"
                      :elevation="hover ? 8 : 2"
                    >
                      <img class="muc-image" :src="item.thumbnail_url" alt="" />
                      <div class="d-flex flex-column pa-6">
                        <h2 class="my-3">{{ item.title }}</h2>
                        <div
                          style="width: 24px; height: 4px; background-color: #61a4bc"
                        ></div>
                        <div class="subcolor--text sub-title-card my-6">
                          {{ item.raw_abstract }}
                        </div>
                      </div>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "MucSelectedTool",
  data: () => ({
    geostoryList: [],
  }),
  components: {
    // HelloWorld
  },
  mounted() {
    let self = this;
    self.getGeostoryTag();
  },
  methods: {
    getGeostoryTag() {
      let self = this;
      let url =
        process.env.VUE_APP_GEONODE_URL +
        "/api/v2/resources?filter{keywords.name}=mucgeostory";
      console.log(url);
      self.axios
        .get(url)
        .then((response) => {
          self.geostoryList = response.data.resources;
          // console.log(response.data.resources);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.header {
  color: white;
}

.muc-image {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
}
</style>
